var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collect"},[_c('div',{staticClass:"head f-c"},[_c('div',{class:_vm.deleteFlag == 'F' ? 'title' : 'titleBorder',on:{"click":function($event){return _vm.titleChange(' ')}}},[_vm._v("正在找料")]),_c('div',{staticClass:"ml-20",class:_vm.deleteFlag != 'F' ? 'title' : 'titleBorder',on:{"click":function($event){return _vm.titleChange('F')}}},[_vm._v("已找到")])]),(_vm.list && _vm.list.length)?_c('div',{staticClass:"list f-w"},[(_vm.list && _vm.list.length)?[_vm._l((_vm.list),function(item,index){return _c('router-link',{key:index,staticClass:"Item",attrs:{"to":{
            name: 'LookMaterialsDetail',
            query: {
                key: _vm.$UrlEncode.encode(JSON.stringify({
                    postId: item.postId
                }))
            }
        }}},[_c('div',{staticClass:"info f-c-b"},[_c('div',{staticClass:"w--100"},[_c('div',{staticClass:"f-c mb-30"},[_c('img',{staticClass:"img",attrs:{"src":item.userAvatarUrl,"alt":""}}),_c('div',[_c('div',{staticClass:"f-c"},[_c('div',{staticClass:"userName"},[_vm._v(_vm._s(item.userName))])])])]),_c('div',{staticClass:"postHead"},[_vm._v(_vm._s(item.postHead))]),_c('div',{staticClass:"previewContent"},[_vm._v(_vm._s(item.previewContent))])]),(item.mobject1)?_c('div',{staticClass:"mobject ml-20"},[_c('img',{attrs:{"src":item.mobject1,"alt":""}})]):_vm._e()]),_c('div',{staticClass:"f-c-b"},[_c('div',{staticClass:"f-c"},_vm._l((item.productTypeList),function(item2,index2){return _c('div',{key:index2,staticClass:"mr-10 f-c"},[_c('div',{staticClass:"cornermark"},[_vm._v(" "+_vm._s(item2)+" ")])])}),0),(item.deleteFlag == 'E')?_c('div',{staticClass:"color-FF4E36 fs-24",staticStyle:{"color":"#FF4E36"}},[_vm._v("已过期")]):(item.deleteFlag == 'S')?_c('div',{staticClass:"color-026EF4",staticStyle:{"color":"#026EF4"}},[_vm._v("未发布")]):(item.deleteFlag == 'F')?_c('div',{staticClass:"color-42DC01",staticStyle:{"color":"#42DC01"}},[_vm._v("已找到")]):_c('div',{staticClass:"color-227FF5",staticStyle:{"color":"#227FF5"}},[_vm._v("到期时间："+_vm._s(item.expirationDate.split(' ')[0]))])])])}),_c('Pagination',{attrs:{"margin":'0px',"total":_vm.total,"pageNum":_vm.currentPage,"limit":_vm.size,"layout":_vm.layout},on:{"update:pageNum":function($event){_vm.currentPage=$event},"update:page-num":function($event){_vm.currentPage=$event},"update:limit":function($event){_vm.size=$event},"pagination":_vm.fetchData}})]:_vm._e()],2):_c('el-empty',{attrs:{"description":"暂无内容"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }