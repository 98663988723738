<template>
    <div class="collect">
        <div class="head f-c">
            <div :class="deleteFlag == 'F' ? 'title' : 'titleBorder'" @click="titleChange(' ')">正在找料</div>
            <div class="ml-20" :class="deleteFlag != 'F' ? 'title' : 'titleBorder'" @click="titleChange('F')">已找到</div>
        </div>
        <div class="list f-w" v-if="list && list.length">
            <template v-if="list && list.length">
                <router-link :to="{
                name: 'LookMaterialsDetail',
                query: {
                    key: $UrlEncode.encode(JSON.stringify({
                        postId: item.postId
                    }))
                }
            }" class="Item" v-for="(item, index) of list" :key="index">
                    <div class="info f-c-b">
                        <div class="w--100">
                            <div class="f-c mb-30">
                                <img class="img" :src="item.userAvatarUrl" alt="">
                                <div>
                                    <div class="f-c">
                                        <div class="userName">{{ item.userName }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="postHead">{{ item.postHead }}</div>
                            <div class="previewContent">{{ item.previewContent }}</div>

                        </div>

                        <div v-if="item.mobject1" class="mobject ml-20">
                            <img :src="item.mobject1" alt="">
                        </div>
                    </div>
                    <div class="f-c-b">
                        <div class="f-c">
                            <div class="mr-10 f-c" v-for="(item2, index2) of item.productTypeList" :key="index2">
                                <div class="cornermark">
                                    {{ item2 }}
                                </div>
                            </div>
                        </div>
                        <div style="color: #FF4E36;" v-if="item.deleteFlag == 'E'" class="color-FF4E36 fs-24">已过期</div>
                        <div style="color: #026EF4;" v-else-if="item.deleteFlag == 'S'" class="color-026EF4">未发布</div>
                        <div style="color: #42DC01;" v-else-if="item.deleteFlag == 'F'" class="color-42DC01">已找到</div>
                        <div style="color: #227FF5;" v-else class="color-227FF5">到期时间：{{ item.expirationDate.split(' ')[0] }}</div>
                    </div>

                </router-link>
                <Pagination :margin="'0px'" :total="total" :pageNum.sync="currentPage" :limit.sync="size"
                    :layout="layout" @pagination="fetchData" />
            </template>
        </div>
        <el-empty v-else description="暂无内容"></el-empty>
    </div>
</template>
<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: '',
    mixins: [PaginationRetention],
    inject: ['reload'],
    data() {
        return {
            deleteFlag: '',
            list: [],
        }
    },
    created() {
    },
    methods: {
        getDate() {
            this.interiorForumBuySearchList(this.paging, this.pagingSize)
        },
        // 找料列表
        interiorForumBuySearchList(page = this.page, size = this.size) {
            let that = this;
            that.$http.interiorForumBuySearchList({
                deleteFlag: that.deleteFlag,
                postUserId: parseInt(that.$store.state.userId),
                postHead: '',
                token: that.$getStorage('token'),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach(item => {
                        let arr = item.productType.split(',');
                        let productTypeList = [];
                        arr.forEach(item2 => {
                            productTypeList.push(UnitAccount.getproductType(item2))
                            item.productTypeList = productTypeList
                        });
                    });
                    that.list = records;
                    // that.list.forEach(item => {
                    //     item.productTypeStr = UnitAccount.getproductType(item.productType);
                    // })
                    that.total = res.data.total;
                    that.currentPage = res.data.current;
                }
            })
        },
        titleChange(deleteFlag){
            this.deleteFlag = deleteFlag;
            this.interiorForumBuySearchList(1, 10);
        }
    },
}
</script>
<style lang="less" scoped>
.collect {
    .head {
        height: 40px;
        padding-bottom: 24px;
        border-bottom: 1px solid #F2F2F2;

        .title {
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            color: #333333;
            line-height: 40px;
            font-weight: bold;
            padding-bottom: 10px;
            cursor: pointer;
        }

        .titleBorder {
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            color: #0363FA;
            line-height: 40px;
            font-weight: bold;
            padding-bottom: 10px;
            border-bottom: 3px solid #0363FA;
            cursor: pointer;
        }
    }

    .list {
        margin-top: 20px;

        .Item {
            // width: 100%;
            width: 840px;
            border-radius: 20px;
            background: #F8F8F8;
            padding: 30px 75px;
            margin-bottom: 20px;
            cursor: pointer;


            .cornermark {
                border-radius: 5px;
                background: linear-gradient(0deg, #026EF4 0%, #38A8F8 100%);
                text-align: center;
                line-height: 16px;
                font-size: 16px;
                color: #fff;
                padding: 5px;
            }

            .info {
                width: 100%;
                position: relative;

                .img {
                    height: 55px;
                    width: 55px;
                    border-radius: 50%;
                    margin-right: 15px;

                }

                .userName {
                    font-size: 20px;
                    color: #333333;
                    line-height: 20px;
                    // width: 240px;
                }



                .postHead {
                    // border: 1px solid red;
                    // width: 100%;
                    width: 440px;
                    font-weight: 500;
                    font-size: 24px;
                    color: #333333;
                    margin-bottom: 15px;
                    overflow: hidden;
                    /* 隐藏超出部分 */
                    text-overflow: ellipsis;
                    /* 显示省略号 */
                    white-space: nowrap;
                    /* 文本不换行 */
                }

                .previewContent {
                    // border: 1px solid red;
                    width: 440px;
                    font-size: 16px;
                    color: #666666;
                    margin-bottom: 15px;
                    overflow: hidden;
                    /* 隐藏超出部分 */
                    text-overflow: ellipsis;
                    /* 显示省略号 */
                    white-space: nowrap;
                    /* 文本不换行 */
                }

                .mobject {
                    img {
                        border-radius: 20px;
                        height: 116px;
                        width: 116px;
                    }

                }

                .comments {
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }

                    font-size: 16px;
                    color: #999999;
                }
            }
        }
    }
}
</style>